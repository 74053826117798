#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Ensures the player is always at the bottom */
  height: 100vh; /* Full viewport height */
  background-repeat: no-repeat;
  background-size: cover; /* Full coverage regardless of orientation */
  background-position: center; /* Centers the background image */
  background-attachment: fixed; /* Keeps the background image static during scroll */
  padding: 0; /* Removes padding around the player */
  margin: 0 auto; /* Center-aligns the container if it's narrower than the viewport */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Matches all fonts */
}

/* Landscape orientation */
@media (orientation: landscape) {
  #root {
    background-image: url('./assets/images/Shaq-Fu-Radio-Shaquille-Oneil-Hip-Hop_Radio-station-1-2000x1024.png');
  }
}

/* Portrait orientation */
@media (orientation: portrait) {
  #root {
    background-image: url('./assets/images/ShaqFu-Radio-Background-Portrait.jpg');
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout needs */
}
